import { useRef, useEffect } from 'react';
import PropertyId from '../../../types/enum/PropertyId';
import { getPropertyValue, getDynamicAttributes, getSanitizedUrlForBackgroundImage, getImageUrlWithPlaceholder } from '../../../utils/blockHelpers';
import MisconfiguredBlock from '../MisconfiguredBlock';
import BlockProps from '../../../types/BlockProps';
import BlockMapping from '../../../types/BlockMapping';
import Page from '../../../types/Page';
import { defaultFilter, DefaultHierarchyContent } from '../../../utils/blockRendererDefaults';
import BlockFilterData from '../../../types/BlockFilterData';
import { hexToRgb } from '../../../utils/colorHelpers'; 
import { MediaDisplayType } from '../../../types/enum/MediaDisplayType';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const parseOverlayStyle = (overlayOpacity: string | null | undefined, overlayColorProperty: string | null | undefined) => {
  const opacity = overlayOpacity ? Math.max(0, Math.min(1, parseFloat(overlayOpacity) || 1)) : 1;
  const colorObj = overlayColorProperty ? JSON.parse(overlayColorProperty) : {};
  let overlayColor = '';
  
  if (colorObj.hex) {
    const [r, g, b] = hexToRgb(colorObj.hex);
    overlayColor = `rgba(${r}, ${g}, ${b}, ${colorObj.a ?? 1})`;
  }

  return { opacity: opacity.toString(), backgroundColor: overlayColor };
};

export function Block({ block, builderProps, blockWrapperData, children}: BlockProps) {
    const videoRef = useRef<HTMLVideoElement>(null);
    const mediaDisplayType = getPropertyValue(block.Properties, PropertyId.MediaDisplayType);
    const loopVideo = getPropertyValue(block.Properties, PropertyId.PlayVideoOnLoop) === "true";
    const overlayStyle = parseOverlayStyle(
      getPropertyValue(block.Properties, PropertyId.CtaOverlayOpacity),
      getPropertyValue(block.Properties, PropertyId.CtaOverlayColor)
    );

    useEffect(() => {
        if (mediaDisplayType === MediaDisplayType.Video && videoRef.current) {
            videoRef.current.autoplay = loopVideo;
            if (loopVideo && videoRef.current.paused) {
                videoRef.current.play()?.catch(() => {});
            }
        }
    }, [mediaDisplayType, loopVideo]);

    if (mediaDisplayType === MediaDisplayType.Video) {
        const videoObject = JSON.parse(getPropertyValue(block.Properties, PropertyId.BackgroundVideo) || '{}');
        const videoPosterObject = JSON.parse(getPropertyValue(block.Properties, PropertyId.BackgroundVideoPoster) || '{}');
        const videoPosterUrl = getSanitizedUrlForBackgroundImage(videoPosterObject?.u);
        const videoSrc = videoObject?.u || "";

        return (
            <div {...getDynamicAttributes({block, builderProps, className: "hero", blockWrapperData})}>
                <div className="video-cta-wrapper">
                    <video
                        ref={videoRef}
                        src={videoSrc}
                        loop={loopVideo}
                        poster={videoPosterUrl}
                        muted
                        playsInline
                        controls={false}
                        className="cta-video"
                    />
                </div>
                <div className="hero-overlay" style={overlayStyle}></div>
                <div className="text-center hero-content text-neutral-content">
                    {children}
                </div>
            </div>
        );
    } else if (mediaDisplayType === MediaDisplayType.MultipleImages) {
        const slidesVal = getPropertyValue(block.Properties, PropertyId.BackgroundImages);
        const slides = slidesVal ? JSON.parse(slidesVal) : [];
        const swiperSpeed = Number(getPropertyValue(block.Properties, PropertyId.ImageScrollSpeed)) || 4000;

        return (
            <div {...getDynamicAttributes({block, builderProps, className: "hero multi-image-cta-wrapper", blockWrapperData})}>
                {slides.length > 0 &&
                    <Swiper
                        key={JSON.stringify(slides)}
                        loop={true}
                        autoplay={{
                            delay: swiperSpeed,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                    >
                        {slides.map((slide: any, index: number) => {
                            const backgroundImage = getSanitizedUrlForBackgroundImage(slide.u);
                            const heroBackgroundStyle = backgroundImage ? {backgroundImage: `url('${backgroundImage}')`} : undefined;

                            return (
                                <SwiperSlide key={index} style={{width: '100%', height: '100%'}}>
                                    <div className="slide-background" style={heroBackgroundStyle}></div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                }
                <div className="multi-image-cta-overlay hero-overlay" style={overlayStyle}></div>
                <div className="text-center hero-content text-neutral-content multi-image-cta-content">
                    {children}
                </div>
            </div>
        );
    } else {
        const imageObject = JSON.parse(getPropertyValue(block.Properties, PropertyId.CtaBackgroundImage) || '{}');
        const imageUrl = getImageUrlWithPlaceholder(imageObject);
        const backgroundImage = getSanitizedUrlForBackgroundImage(imageUrl);
        const heroBackgroundStyle = backgroundImage ? {backgroundImage: `url('${backgroundImage}')`} : null;

        return (
            <div {...getDynamicAttributes({block, builderProps, className: "hero", styles: heroBackgroundStyle, blockWrapperData})}>
                <div className="hero-overlay" style={overlayStyle}></div>
                <div className="text-center hero-content text-neutral-content">
                    {children}
                </div>
            </div>
        );
    }
}

export function hasContentToRender(block: BlockMapping, _page: Page | null, childrenHaveContentToRender: boolean) {
    return getPropertyValue(block.Properties, PropertyId.CtaBackgroundImage)
        || getPropertyValue(block.Properties, PropertyId.BackgroundVideo)
        || getPropertyValue(block.Properties, PropertyId.BackgroundImages)
        || childrenHaveContentToRender;
}

export function usePlaceholder(block: BlockMapping, page: Page | null, childrenHaveContentToRender: boolean) {
    return !hasContentToRender(block, page, childrenHaveContentToRender);
}

export function Placeholder(props: BlockProps) {
    return <MisconfiguredBlock {...props} />
}

export function HierarchyContent(block: BlockMapping): JSX.Element | null {
    return DefaultHierarchyContent(block);
}

export function filter(block: BlockMapping, filterText: string, _filterData?: BlockFilterData): boolean {
    return defaultFilter(block, filterText);
}
