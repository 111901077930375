import { useEffect, useState } from "react";
import { Alert, AlertTitle, Button, Link, TextField, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from "@mui/system";
import useApiPostCallbackAsync from "../hooks/useApiPostCallbackAsync";
import NavBar from "./common/NavBar";
import Loading from './common/Loading';
import ErrorMessage from "./common/ErrorMessage";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SuccessMessage from "./common/SuccessMessage";

interface SitePlans {
  PageNumber: number;
  PageSize: number;
  TotalPages: number;
  Results: Array<SitePlan>
};

interface SitePlan {
  SiteId: string | null;
  PublishedBusinessName: string;
  Domain: string;
  RevSiteObjectId: string;
  ListingId: string;
  CreatedDate: string;
  PlanId: string;
  LocationId: string;
  PreviewUrl?: string;
  Warnings?: Array<string>;
  MassMigrate?: boolean;
  MigrationErrors?: string[];
}

interface SearchRequest {
  PageNumber: number;
  PageSize: number;
  CutoffDate?: string;
  ListingId?: string | null;
}

interface MigrationRequest {
  PlanId: string;
  RevSiteObjectId: string;
  LocationId: string;
  ListingId: string;
  Domain: string;
  TemplateId: string;
  Industry: number;
};

interface MigrationResponse {
  PreviewUrl: string;
  SiteId: string;
  Warnings: Array<string>;
}

interface Warning {
  SiteId: string,
  Domain: string,
  ListingId: number | null,
  Warnings: string[]
}

export default function Migrate() {
  const pageSize = 20;
  const massMigratePageSize: number = (window as any).CONFIG.MigrationPageSize;
  const massMigrateBatchSize: number = (window as any).CONFIG.MigrationBatchSize;
  const [searchRequest, setSearchRequest] = useState<SearchRequest>({
    PageNumber: 1,
    PageSize: pageSize,
    CutoffDate: "",
  });
  const [doSearch, setDoSearch] = useState<boolean>(false);
  const [sitePlans, setSitePlans] = useState<SitePlans>({
    PageNumber: 0,
    PageSize: 0,
    TotalPages: 0,
    Results: [],
  });
  const [errorText, setErrorText] = useState<string | null>(null);
  const [processingResults, setProcessingResults] = useState(false);
  const [listingId, setListingId] = useState('');
  const [includePreviouslyMigrated, setIncludePreviouslyMigrated] = useState(false);
  const [migrateSitesWithWarningErrors, setMigrateSitesWithWarningErrors] = useState(false);
  const [retrievingSitePlans, setRetrievingSitePlans] = useState(false);
  const [retrievingSitePlansMessage, setRetrievingSitePlansMessage] = useState("");
  const [massMigrating, setMassMigrating] = useState(false);
  const [massMigratingMessage, setMassMigratingMessage] = useState("");
  const [cancelMassMigration, setCancelMassMigration] = useState(false);
  const [migrateIndex, setMigrateIndex] = useState<number | null>(null);
  const [plansToMigrate, setPlansToMigrate] = useState<SitePlan[]>([]);
  const [showMigrationSuccess, setShowMigrationSuccess] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  const [{ isLoading: isSearching, isError: isSitePlansSearchError, errorResponse: sitePlansSearchError }, searchSitePlans]
        = useApiPostCallbackAsync('/migration/search', []) as any;
  const [{ isLoading: isMigrating, errorResponse: migrationError }, migrateSite]
        = useApiPostCallbackAsync(`/migrate`) as any;
  // eslint-disable-next-line no-empty-pattern
  const [{}, searchVersions] = useApiPostCallbackAsync('') as any;
  // eslint-disable-next-line no-empty-pattern
  const [{ data: warnings, isLoading: loadingWarnings }, searchWarnings] = useApiPostCallbackAsync('/migrate/warnings', []) as any;

  const LinkButtonStyle = {
    display: 'inline-block',
    padding: '6px 8px',
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    borderRadius: '4px',
    color: 'primary.main',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: 'rgba(98, 0, 238, 0.04)'
    }
  };

  useEffect(() => {
    searchWarnings({}, () => {
      setDoSearch(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingReport && sitePlans && !loadingWarnings) {
      doReportDownloadAnchor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingReport, loadingWarnings]);

  const loadWarningsReport = () => {
    searchAll((plans: SitePlans | null) => {
      if (!loadingReport) {
        setLoadingReport(true);
      }
    });
  }

  const doReportDownloadAnchor = () => {
    let mappedData = 'ListingId,Domain,Warning\r\n';
    sitePlans?.Results?.forEach((p: SitePlan) => {
      const siteWarnings = warnings.find((w: any) => parseInt(p.ListingId) === w.ListingId);
      if (siteWarnings?.Warnings && siteWarnings.Warnings.length > 0) {
        siteWarnings.Warnings.forEach((w: string) => {
          const warning = w.replace(/[\r\n]/g, "").replace(/"/g, '""');
          mappedData += `${p.ListingId},${p.Domain},"${warning}"\r\n`;
        })
      }
      else if (siteWarnings === undefined) {
        mappedData += `${p.ListingId},${p.Domain},no migration attempted yet\r\n`;
      }
      else {
        mappedData += `${p.ListingId},${p.Domain},no warnings\r\n`;
      }
    });

    setLoadingReport(false);
    const link = document.createElement('a');
    link.href = 'data:text/csv,' +  encodeURIComponent(mappedData);
    link.download = 'Site Warnings Report.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    setErrorText(null);
    if (!loadingWarnings && doSearch) {
      setDoSearch(false);
      searchSitePlans(
        searchRequest,
        (res: SitePlans) => {
          setProcessingResults(true);
          let versionCount = 0;
          for (const site of res.Results) {
            if (site.SiteId) {
              versionCount++;
            }
          }
          const setPlansOnceFinished = (version: boolean) => {
            if (version) {
              versionCount--;
            }

            if (versionCount === 0) {
              setProcessingResults(false);
              setSitePlans({...res, Results: sitePlans.Results.concat(res.Results)});
            }
          };

          setPlansOnceFinished(false);
          
          for (const site of res.Results) {
            const warning = warnings.find((w: Warning) => 
              w.ListingId !== null && w.ListingId.toString() === site.ListingId);
            site.Warnings = warning?.Warnings;

            if (site.SiteId) {
              searchVersions(
                { 
                  Page: 1, 
                  PageSize: 1, 
                  ActivePreviewOnly: true 
                }
                , (res: any) => {
                  if (res.Results.length > 0) {
                    site.PreviewUrl = res.Results[0].PreviewUrl;
                  }
                  setPlansOnceFinished(true);
                }
                , () => { 
                  setPlansOnceFinished(true);
                }
                , `/site/${site.SiteId}/version/search`);
              }
            }
        },
        (error: any) => {
          if (error.message) {
            setErrorText(error.message);
          }
        },
        '/salesforce/v2/revsites/migration/search',
        true,
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest, doSearch, loadingWarnings]);

  const search = () => {
    setSitePlans({
      PageNumber: 0,
      PageSize: 0,
      TotalPages: 0,
      Results: [],
    });
    setSearchRequest({
      PageNumber: 1,
      PageSize: pageSize,
      ListingId: listingId,
      CutoffDate: "",
    });
    setDoSearch(true);
  };

  const doMigrate = (siteToMigrate: SitePlan, onSuccess: (res: MigrationResponse) => void
    , onFailure: (error: any) => void) => {
    migrateSite(
      {
        PlanId: siteToMigrate.PlanId,
        RevSiteObjectId: siteToMigrate.RevSiteObjectId,
        LocationId: siteToMigrate.LocationId,
        ListingId: siteToMigrate.ListingId,
        Domain: siteToMigrate.Domain,
        TemplateId: (window as any).CONFIG.TemplateId,
        Industry: 101,
      } as MigrationRequest,
      onSuccess,
      onFailure);
  };

  const migrateSiteAtIndex = (siteToMigrateIndex: number = -1) => {
    if (siteToMigrateIndex > -1) {
      const siteToMigrate = sitePlans.Results[siteToMigrateIndex];
      if (siteToMigrate) {
        setErrorText(null);
        doMigrate(
          siteToMigrate, 
          (res: MigrationResponse) => {
            setSitePlans((current: SitePlans) => { 
              const updatedSitePlans: Array<SitePlan> = Array.from(current.Results);
              updatedSitePlans[siteToMigrateIndex] = {...updatedSitePlans[siteToMigrateIndex], ...res};
              return {...current, Results: updatedSitePlans};
            });
            searchWarnings({});
          }, 
          (error: any) => {
            if (error.message) {
              setErrorText(error.message);
            }
          });
        }
      }
  };

  const checkedLength = sitePlans ? sitePlans.Results.filter(p => p.MassMigrate).length : 0;
  const migrateAllBtnText = checkedLength > 0 ? `Migrate ${checkedLength} Sites` : "Mass Migrate";

  useEffect(() => {
    if (plansToMigrate !== null && plansToMigrate.length > 0 && migrateIndex !== null) {
      if (migrateIndex < plansToMigrate.length && !cancelMassMigration) {
        let currentBatchCount = massMigrateBatchSize;
        if (migrateIndex + currentBatchCount >= plansToMigrate.length) {
          currentBatchCount = plansToMigrate.length - migrateIndex;
        }

        setMassMigratingMessage(`Migrating sites ${migrateIndex + 1}${migrateIndex + currentBatchCount !== migrateIndex + 1 ? `-${migrateIndex + currentBatchCount}` : ''} of ${plansToMigrate.length}`);

        const migrateComplete = () => {
          currentBatchCount = currentBatchCount - 1;

          if (currentBatchCount === 0) {
            setMigrateIndex(migrateIndex + massMigrateBatchSize);
          }
        }

        for (let i = 0; i < currentBatchCount; i++) {
          const plan = plansToMigrate[migrateIndex + i];
          doMigrate(
            plan, 
            (res: MigrationResponse) => {
              setSitePlans((current: SitePlans) => { 
                const updatedSitePlans: Array<SitePlan> = Array.from(current.Results);
                for (let i = 0; i < updatedSitePlans.length; i++) {
                  if (updatedSitePlans[i].PlanId === plan.PlanId) {
                    updatedSitePlans[i] = {...updatedSitePlans[i], ...res };
                    break;
                  }
                }
                return {...current, Results: updatedSitePlans};
              });
              migrateComplete();
            },
            (error: any) => {
              setSitePlans((current: SitePlans) => { 
                let migrationErrors: string[] = ["Error migrating site"];

                if (error && error.body?.Errors?.length > 0) {
                  migrationErrors = error.body.Errors.map((e: any, i: number) => e.Message);
                }
                const updatedSitePlans: Array<SitePlan> = Array.from(current.Results);
                for (let i = 0; i < updatedSitePlans.length; i++) {
                  if (updatedSitePlans[i].PlanId === plan.PlanId) {
                    updatedSitePlans[i] = {...updatedSitePlans[i], MigrationErrors: migrationErrors};
                    break;
                  }
                }
                return {...current, Results: updatedSitePlans};
              });
              migrateComplete();
          });
        }
      } else {
        setMassMigrating(false);
        setMassMigratingMessage("");
        setShowMigrationSuccess(true);
        searchWarnings({});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansToMigrate, migrateIndex]);

  const searchAll = (nextAction: (plans: SitePlans | null) => void) => {
    if (checkedLength === 0) {
      setSitePlans({
        PageNumber: 0,
        PageSize: 0,
        TotalPages: 0,
        Results: [],
      });
    }

    setShowMigrationSuccess(false);
    setErrorText(null);
    setCancelMassMigration(false);
    setPlansToMigrate([]);
    setMigrateIndex(null);

    const continueSearching = (page: number, plans: SitePlans | null) => {
      if ((plans === null || page <= plans.TotalPages) && checkedLength === 0) {
        setRetrievingSitePlansMessage(`Retrieving sites to migrate page ${page}${plans !== null ? ` of ${plans.TotalPages}` : ''}`);
        searchSitePlans(
          {
            PageNumber: page,
            PageSize: massMigratePageSize,
            ListingId: null,
            CutoffDate: "",
          },
          (res: SitePlans) => {
            let versionCount = 0;
            for (const site of res.Results) {
              if (site.SiteId) {
                versionCount++;
              }
            }
            const setPlansOnceFinished = (version: boolean) => {
              if (version) {
                versionCount--;
              }

              if (versionCount === 0) {
                const newSitePlans = plans === null ? res : {...res, Results: plans.Results.concat(res.Results)};
                continueSearching(page + 1, newSitePlans);
              }
            };

            setPlansOnceFinished(false);
            
            for (const site of res.Results) {
              const warning = warnings.find((w: Warning) => 
              w.ListingId !== null && w.ListingId.toString() === site.ListingId);
              site.Warnings = warning?.Warnings;

              if (site.SiteId) {
                searchVersions(
                  { 
                    Page: 1, 
                    PageSize: 1, 
                    ActivePreviewOnly: true 
                  }
                  , (res: any) => {
                    if (res.Results.length > 0) {
                      site.PreviewUrl = res.Results[0].PreviewUrl;
                    }
                    setPlansOnceFinished(true);
                  }
                  , () => { 
                    setPlansOnceFinished(true);
                  }
                  , `/site/${site.SiteId}/version/search`);
                }
              }
          },
          (error: any) => {
            if (error.message) {
              setErrorText(error.message);
            }
          },
          '/salesforce/v2/revsites/migration/search',
          true,
        );
      } else {
        if (plans !== null) {
          setSitePlans(plans);
        }

        setRetrievingSitePlans(false);
        nextAction(plans);
      }
    }

    setRetrievingSitePlans(true);
    continueSearching(1, null);
  }

  const migrateAll = () => {
    searchAll((plans: SitePlans | null) => {
      let plansToMigrate = plans?.Results ?? sitePlans?.Results;

      if (plansToMigrate) {
        let filteredPlans: SitePlan[] = [];

        if (checkedLength > 0) {
          filteredPlans = plansToMigrate.filter(p => p.MassMigrate);
        } else if (migrateSitesWithWarningErrors) {
          filteredPlans = plansToMigrate.filter(p => p.Warnings?.find(i => i.toLowerCase().indexOf('error') > -1) !== undefined)
        } else if (!includePreviouslyMigrated) {
          filteredPlans = plansToMigrate.filter(p => !p.SiteId);
        } else {
          filteredPlans = plansToMigrate;
        }

        for (let plan of filteredPlans) {
          plan.MigrationErrors = [];
        }

        if (filteredPlans.length > 0) {
          setMassMigrating(true);
          setPlansToMigrate(filteredPlans);
          setMigrateIndex(0);
        } else {
          setErrorText("No sites available to migrate");
        }
      }
    })
  }

  const cancelMigrationHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setCancelMassMigration(true);
  };

  return (
    <>
      <NavBar title="Migrate" />
      { (processingResults || loadingWarnings || (isSearching && !retrievingSitePlans)) && <Loading /> }
      { retrievingSitePlans && <Loading message={retrievingSitePlansMessage} /> }
      { massMigrating && <Loading message={cancelMassMigration ? "Cancelling..." : massMigratingMessage} cancel={cancelMigrationHandler} /> }
      { isMigrating && !massMigrating && <Loading message={'Migrating Site. Please be patient.' } /> } 
      { !isMigrating && !massMigrating && !retrievingSitePlans && 
        <Box sx={{display: "flex", flexDirection: "column", height: "calc(100% - 48px)", overflow: "auto"}}>
          <div style={{ display: "flex", margin: "16px" }}>
            <div>
              <TextField
                  type="text"
                  label="Search By Salesforce RevSite ID"
                  value={listingId}
                  sx={{ width: "300px" }}
                  onChange={(e) => setListingId(e.target.value) }
                  InputProps={{
                      startAdornment: (
                      <InputAdornment position="start">
                          <SearchIcon sx={{color: "black"}} />
                      </InputAdornment>
                      ),
                      endAdornment: !listingId ? null : (
                      <InputAdornment position="end">
                          <ClearIcon sx={{ cursor: "pointer", '&:hover': { color: "secondary.main" } }} onClick={() => setListingId('') } />
                      </InputAdornment>
                      )
                  }}
                />
              <Button onClick={search} variant="contained" sx={{ marginLeft: "8px" }}>Search</Button>
            </div>
            <div style={{ flex: "1" }}>
              <div style={{ float: "right" }}>
                <Button onClick={loadWarningsReport} variant="contained">Generate Site Warnings Report</Button>
                {loadingReport && <Loading message={'Downloading Warnings. Please be patient.' } />}
              </div>
            </div>
            <div style={{ flex: "1" }}>
              <div style={{ float: "right" }}>
                <Button onClick={migrateAll} variant="contained" sx={{ marginRight: "8px" }}>{migrateAllBtnText}</Button>
                <br />
                <FormControlLabel 
                  sx={{ visibility: checkedLength === 0 ? "visible" : "hidden"}}
                  control={
                    <Checkbox       
                      checked={includePreviouslyMigrated || migrateSitesWithWarningErrors}
                      disabled={migrateSitesWithWarningErrors}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setIncludePreviouslyMigrated(event.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }} 
                    />
                  } 
                  label="Include previously migrated sites" 
                />
                <br />
                <FormControlLabel 
                  control={
                    <Checkbox       
                      checked={migrateSitesWithWarningErrors}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setMigrateSitesWithWarningErrors(event.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }} 
                    />
                  } 
                  label="Re-migrate sites with errors" 
                />
              </div>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, tableLayout: 'fixed' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                    <TableCell sx={{width:'200px'}}>Plan Business / Location name</TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ textAlign: "center", width: '50px' }}>
                      Migrate
                      <br />
                      <Checkbox       
                        checked={sitePlans?.Results?.length > 0 && sitePlans.Results.length === checkedLength}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setSitePlans((current: SitePlans) => { 
                            const allChecked = sitePlans?.Results?.length === checkedLength;
                            const updatedSitePlans: Array<SitePlan> = Array.from(current.Results);
                            for (let plan of updatedSitePlans) {
                              plan.MassMigrate = !allChecked;
                            }
                            return {...current, Results: updatedSitePlans}
                          });
                        }}
                        inputProps={{ 'aria-label': 'controlled' }} 
                      />
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sitePlans && sitePlans.Results && sitePlans.Results.map((sitePlan: SitePlan, index: number) => (
                  <TableRow
                      key={sitePlan.PlanId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: '#FFFFFF' }}
                  >
                    <TableCell component="th" scope="row" sx={{wordBreak:'break-all'}}>
                        {sitePlan.PublishedBusinessName}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ marginBottom: '10px' }}>
                        <Button onClick={() => {
                          migrateSiteAtIndex(index);
                        }}>{sitePlan.SiteId ? 'Re-Migrate' : 'Migrate'}</Button>
                        <Link href={`${sitePlan.Domain.indexOf('http') === -1 ? 'https://' : ''}${sitePlan.Domain}`} target="_blank" rel="noreferrer" sx={LinkButtonStyle}>View Legacy</Link>
                        {sitePlan.PreviewUrl && 
                          <Link href={sitePlan.PreviewUrl} target="_blank" rel="noreferrer" sx={{...LinkButtonStyle, marginLeft: "10px"}}>View Migrated Site Preview</Link>
                        }
                        {sitePlan.SiteId && 
                          <Link href={`/site/${sitePlan.SiteId}`} target="_blank" rel="noreferrer" sx={{...LinkButtonStyle, marginLeft: "10px"}}>Open Site Builder</Link>
                        }
                        <Link href={`${(window as any).CONFIG.SalesforceBaseUrl}/lightning/r/Plan__c/${sitePlan.PlanId}/view`} target="_blank" rel="noreferrer" sx={{...LinkButtonStyle, marginLeft: "10px"}}>Open Salesforce</Link>
                      </Box>
                      {sitePlan.MigrationErrors && sitePlan.MigrationErrors.length > 0 &&
                        <Alert severity="error" sx={{ marginBottom: "8px", TextWrap:'wrap', wordBreak:'break-all' }}>
                          <AlertTitle>Error</AlertTitle>
                          <ul>
                            {sitePlan.MigrationErrors.map((err, index) => <li key={index}>{err}</li>)}
                          </ul>
                        </Alert>
                      }
                      {sitePlan.Warnings && sitePlan.Warnings?.length > 0 && sitePlan.Warnings.find(i => i.toLowerCase().indexOf('error:') === 0) === undefined &&
                        <Alert severity="warning" sx={{ marginBottom: "8px", TextWrap:'wrap', wordBreak:'break-all' }}>
                          <AlertTitle>Warning</AlertTitle>
                          <ul>
                            {sitePlan.Warnings.map((warning, index) => <li key={index}>{warning}</li>)}
                          </ul>
                        </Alert>
                      }
                      {sitePlan.Warnings && sitePlan.Warnings?.length > 0 && sitePlan.Warnings.find(i => i.toLowerCase().indexOf('error:') === 0) !== undefined &&
                        <Alert severity="error" sx={{textWrap:'wrap', wordBreak:'break-all'}}>
                          <AlertTitle>Error</AlertTitle>
                          <ul>
                            {sitePlan.Warnings.map((warning, index) => <li key={index}>{warning}</li>)}
                          </ul>
                        </Alert>
                      }
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Checkbox       
                        checked={sitePlan.MassMigrate ?? false}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setSitePlans((current: SitePlans) => { 
                            const updatedSitePlans: Array<SitePlan> = Array.from(current.Results);
                            updatedSitePlans[index] = {...updatedSitePlans[index], MassMigrate: event.target.checked};
                            return {...current, Results: updatedSitePlans}
                          });
                        }}
                        inputProps={{ 'aria-label': 'controlled' }} 
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          { sitePlans.PageNumber < sitePlans.TotalPages && 
            <Button onClick={() => {
              setSearchRequest({...searchRequest, PageNumber: (searchRequest?.PageNumber ?? 0) + 1} as SearchRequest);
              setDoSearch(true);
            }}>Load More</Button>
          }
        </Box>
      }
      <ErrorMessage
          message={sitePlansSearchError || migrationError || errorText}
          duration={5000}
          visible={isSitePlansSearchError || errorText}
      />
      <SuccessMessage
          message={"Mass Migration Completed"}
          duration={5000}
          visible={showMigrationSuccess}
      />
    </>
  );
}